import { ErrorMessage } from './field'
import TimePicker from 'antd/lib/time-picker';

export const TimePickerAdapter = ({ input, meta, label, dark = false, className="", icon, ...rest }) => (
    <div className={`flex flex-col min-w-[130px] ${className||''}`}>
        <label className={`block mb-4 h-7 ${dark ? 'text-white' : 'text-pink-500'}`}>
            <div className="flex flex-row items-center gap-2">
                {icon}
                <span>{label}</span>
            </div>
        </label>
        <TimePicker
            {...input}
            onSelect={input.onChange}
            {...rest}
            disabledTime={() => ({
                disabledSeconds: (selectedHour, selectedMinute) => 0,
            })}
            minuteStep={15}
            hideDisabledOptions={true}
            format="HH:mm"
            inputReadOnly // hides keyboard on mobile
            placement="bottomRight"
            className={`border h-10 pl-0 ${dark ? 'border-gray-500' : 'border-pink-500'} ${(meta.touched && meta.error) ? 'border-red-500': ''}`}
            popupClassName="timepicker-popup min-w-[200px] sm:min-w-[250px] min-h-[300px]"
        />
        <ErrorMessage>{meta.touched && meta.error}</ErrorMessage>
    </div>
)
